@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  height: 100vh;
}

body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

.textarea {
  resize: none;
}

.textarea:focus {
  box-shadow: none;
  border-color: var(--fallback-bc,oklch(var(--bc)/0));
  outline-style: solid;
  outline-width: 0px;
  outline-offset: 0px;
  outline-color: var(--fallback-bc,oklch(var(--bc)/0));
}